@use '../../../../../../resources/scss/mixins' as m;

.unique-selling-points {
  @include m.flex(start, center);
  @include m.hide-scrollbars;
  @include m.section-padding-sizes();
  gap: 16px;
  overflow-x: scroll;
  margin: 0;
  border-top: 1px solid var(--card-border);
  border-bottom: 1px solid var(--card-border);
  background: var(--cards-background);

  @include m.bp(lg) {
    gap: 24px;
  }

  @for $i from 1 through 8 {
    &[data-usps="#{$i}"] &__group {
      animation-duration: #{4 * $i}s;
    }
  }

  &__group {
    @include m.flex(start, center);
    flex: 0 0 auto;
    gap: inherit;
    padding: 12px 0;
    will-change: transform;
    animation: scrolling linear infinite paused;
    transform: translateX(calc(-100% - 16px));

    @include m.bp(sm) {
      transform: translateX(calc(-100% - 24px));
    }

    &--clone {
      display: none;
    }

    .usp-item {
      @include m.flex(start, center);
      margin: 0;
      gap: 4px;

      @include m.bp(sm) {
        gap: 8px;
      }

      .icon {
        color: var(--brand-main);
        flex: 0 0 auto;
      }
    }
  }
}

@keyframes scrolling {
  0% {
    transform: translateX(0);
  }
}