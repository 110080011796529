/*
 * Variables
 */
/* Grid sizing */
/* Page and section sizing */
/* Font weights */
/* z-indexes */
/* Select2 dropdown */
/* Button sizing */
/* Image alignments and ratios */
/* Section padding */
/*
 *  Overlays
 */
/* Logo sizes for navigation and footer */
/* Theme colors */
/* Grid columns and gaps */
:root {
  --grid-gap-xs: 16px;
  --grid-gap-sm: 24px;
  --grid-col-xs: 6;
  --grid-col-sm: 12;
  --grid-col-xl: 18;
  --max-grid-width: calc(var(--grid-width) * var(--grid-col-xl) + var(--grid-gap-sm) * var(--grid-col-xl));
}

/*
 * Transitions
 */
/* Element shadows */
/*
 *  # Experimental SCSS Styling Mixins V1.0
 *  A collection of non standard mixins.
 *
 *  Warning!  Some of these mixins use non-standard properties to solve specific cases,
 *            while other mixins impact user accessibility. These mixins should be used carefully
 *            or not at all when more stable options are available.
 */
/*
 * # Hide Scrollbars
 * Hide scrollbars inside the current element.
 *
 * Warning! This mixin impacts accessibility by hiding scrollbars inside an scrolling element.
 *          Make sure to use a replacement element to indicate the scroll position.
 */
/*
 * # Browser Exceptions
 * Create styling for specific browsers.
 *
 * Warning! This mixin is build with non-standard queries and properties and thus prone to error.
 *          Only use this if nothing else can solve your case.
 *
 * $browser - String - set the browser name write styling for. | Options: firefox, chrome, safari, internet-explorer
 */
/*
 * # Detects the users inputMethod
 * Only activate your styling when a certain inputMethod is used
 *
 * Warning! This mixin is build with non-standard queries and properties and thus prone to error.
 *
 */
/*
 * # Set styling when interacting with an element
 *
 * $mode (optional)
 *        - active -  uses the :active selector instead of :focus and :hover.
 *        - focus  -  only sets :focus and :focus-within.
 *
 * Warning! This feature is still experimental and thus subject to possible changes in the future.
 *
 */
/*
 *  # SCSS Layout Mixins V1.0
 *  A set of mixins to rapidly build layouts.
 *
 */
/*
 * # Breakpoints
 * Add styling for specific screen sizes.
 *
 * $minSize - Set the min-width property | options: xs, sm, md, lg, xl, xxl
 * $maxSize - Set the max-width property | options: xs, sm, md, lg, xl, xxl
 *
 */
/*
 * # Flex layout
 * Create flex layouts inside a container element.
 *
 *
 */
/*
 * # Grid containers
 * Create grid views inside a container element.
 *
 * @param Integer $col-xs - Set the amount of columns for xs and default screen sizes
 * @param Integer $col-sm - Set the amount of columns for sm screen sizes
 * @param Integer $col-lg - Set the amount of columns for lg screen sizes
 * @param Integer $col-xl - Set the amount of columns for xl and xxl screen sizes
 * @param {px, %} $max-col-width - Overwrite the default max width on large screens
 */
/*
 * # Element positioning
 * Shortcuts for absolute, fixed and sticky positioning.
 *
 * $position  - String - Either 'fixed', 'absolute' or 'sticky' to set the css position property.
 * $top       - String - Set the value for the top property.    | Default: null
 * $right     - String - Set the value for the right property.  | Default: null
 * $bottom    - String - Set the value for the bottom property. | Default: null
 * $left      - String - Set the value for the left property.   | Default: null
 */
/*
 * # Section Padding Sizes
 * Renders margin or padding sizes for a container.
 *
 * $mode - either margin or padding.
 */
/*
 *  # SCSS Styling Mixins V1.0
 *  These mixins are made to quickly build SCSS layouts.
 *
 */
/*
 * # Background overlay
 * Adds a dark overlay as :after pseudo selector to backgrounds
 */
/*
 * # Theme color loop
 * Loops over all theme colors to generate theme color variants
 *
 * Available variables in @content:
 * - $type | The theme color type, either 'brand' or 'state'.
 * - $color | The current theme color name.
 */
/*
 * # Card styling
 * All card styling properties in a single mixin.
 */
.banner-block {
  grid-auto-flow: dense;
  min-height: 320px;
}
.banner-block--card-background {
  background: var(--cards-background);
  border: 1px solid var(--card-border);
}
.banner-block__copy {
  display: flex;
  flex-direction: column;
  grid-column: 1/-1;
  align-self: center;
  z-index: 10;
  margin: 16px;
}
@media (min-width: 768px) {
  .banner-block__copy {
    padding: 64px 0;
    margin: 0 24px;
  }
}
@media (min-width: 1600px) {
  .banner-block__copy {
    margin: 0 32px;
  }
}
@media (min-width: 1280px) {
  .banner-block__copy {
    margin: 0 40px;
  }
}
.banner-block__copy--align-center {
  align-items: center;
  text-align: center;
}
.banner-block__copy--align-right {
  align-items: flex-end;
}
.banner-block__copy .heading__title {
  padding-top: 0;
}
.banner-block__copy__actions {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: column;
  gap: 8px;
  grid-column: 1/-1;
  margin: 16px 0 0;
}
@media (min-width: 768px) {
  .banner-block__copy__actions {
    flex-direction: row;
    width: auto;
    margin: 24px 0 0;
    gap: 16px;
  }
}
.banner-block__copy__actions__icon {
  margin-left: 6px;
}
.banner-block__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  z-index: 0;
}
.banner-block__background:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  background: rgba(255, 255, 255, 0.56);
  border-width: var(--banner-border, 0);
  border-style: solid;
  border-color: var(--banner-border);
  border-radius: var(--shape-border-radius);
}
.banner-block__background__heading {
  text-shadow: 0 0 40px rgba(0, 0, 0, 0.4);
}
.banner-block__background__picture {
  width: 100%;
  height: 100%;
}
.banner-block__background__picture .image {
  height: 100%;
  border-radius: 0;
}
.banner-block[data-dark-mode] .banner-block__background:after {
  background: rgba(0, 0, 0, 0.56);
}

.cards .scroll-handler-container__slider {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}
.cards .scroll-handler-container__slider .card {
  flex: 0 0 84%;
  max-width: 400px;
  margin-top: unset;
}
.cards__copy {
  grid-column: 1/-1;
  max-width: 640px;
}
.cards > .button {
  grid-column: 1/-1;
  margin-top: 16px;
  width: auto;
  display: flex;
}
@media (min-width: 768px) {
  .cards > .button {
    justify-self: end;
  }
}

.card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  grid-column: 1/-1;
  margin: 24px 0 0;
  text-decoration: none;
}
@media (min-width: 768px) {
  .card {
    grid-column: span 6;
  }
}
.card[href], .card[href]:visited, .card[href]:hover, .card[href]:visited:hover {
  color: inherit;
}
.card--background {
  background: var(--cards-background);
  border-width: var(--card-border, 0);
  border-style: solid;
  border-color: var(--card-border);
  border-radius: var(--shape-border-radius);
  box-shadow: var(--element-shadow);
  overflow: hidden;
}
.card--background[href]:focus, .card--background[href]:focus-within {
  border-color: var(--brand-main-active);
  background: linear-gradient(rgba(var(--brand-main-active-rgb), 0.12), rgba(var(--brand-main-active-rgb), 0.12)), var(--cards-background);
}
.card--background[href]:focus .button, .card--background[href]:focus-within .button {
  background: var(--brand-main-active);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .card--background[href]:hover {
    border-color: var(--brand-main-active);
    background: linear-gradient(rgba(var(--brand-main-active-rgb), 0.12), rgba(var(--brand-main-active-rgb), 0.12)), var(--cards-background);
  }
  .card--background[href]:hover .button {
    background: var(--brand-main-active);
  }
}
.card--background .card__content {
  padding: 0 16px;
  box-sizing: border-box;
}
.card--background .heading__title, .card--background .heading__description * {
  color: var(--text-color-dark);
}
.card--background[data-dark-mode] .heading__title, .card--background[data-dark-mode] .heading__description * {
  color: var(--text-color-light);
}
.card--background .picture, .card--background .video video {
  border-radius: unset;
  border-bottom: 1px solid var(--card-border);
}
@media (min-width: 1280px) {
  .card--wide {
    grid-column: span 6;
  }
}
@media (min-width: 1600px) {
  .card--wide {
    grid-column: span 9;
  }
}
.card__content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
  width: 100%;
  margin: 12px 0;
}
.card__content__note {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  color: var(--mono-6);
}
.card__content__note__icon {
  padding: 0 8px 0 0;
  font-size: 16px;
}
.card__content .heading {
  flex: 1 0 auto;
  margin-top: 0;
  width: 100%;
}
.card__content .heading__title {
  padding-top: 0.2rem;
}
@media (min-width: 768px) {
  .card__content .heading__title {
    gap: 8px;
  }
}
.card__content .heading__title__icon {
  font-size: inherit;
  margin-right: 4px;
}
.card__content .heading__description p {
  margin: 0.2rem 0 0;
}
.card__content .button {
  flex: 0 0 auto;
  margin-top: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.citations {
  margin-left: -;
  margin-right: -;
  color: var(--text-color-dark);
  padding: var(--section-padding) 16px !important;
}
@media (min-width: 768px) {
  .citations {
    padding: var(--section-padding) 24px !important;
  }
}
@media (min-width: 1024px) {
  .citations {
    padding: var(--section-padding) 40px !important;
  }
}
.citations[data-dark-mode] {
  color: var(--text-color-light);
}
.citations[data-dark-mode] .heading__title {
  color: var(--heading-color-light);
}
.citations--card {
  background: var(--cards-background);
  border: 1px solid var(--card-border);
  border-radius: var(--shape-border-radius);
}
.citations .heading {
  position: relative;
  z-index: 10;
}
.citations .heading__title {
  color: var(--text-color-dark);
}
.citations .heading__description {
  color: inherit;
}
.citations__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: unset;
  z-index: 0;
}
.citations__background:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  background: rgba(0, 0, 0, 0.56);
}
@media (min-width: 1280px) {
  .citations__background:after {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.56) 100%, rgba(0, 0, 0, 0) 100%);
  }
}
@media (min-width: 1600px) {
  .citations__background:after {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.56) 100%, rgba(0, 0, 0, 0) 100%);
  }
}
.citations__background .video__media {
  border-radius: unset;
}
.citations[data-background] {
  width: 100%;
  margin: 0;
  border-radius: unset;
}

.citations .scroll-handler-container__slider {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  mask-image: unset;
}
.citations .scroll-handler-container__buttons {
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;
}
@media (min-width: 768px) {
  .citations .scroll-handler-container__buttons {
    margin-left: 24px;
    margin-right: 24px;
  }
}
@media (min-width: 1024px) {
  .citations .scroll-handler-container__buttons {
    margin-left: 40px;
    margin-right: 40px;
  }
}
@media (min-width: 1280px) {
  .citations .scroll-handler-container__buttons {
    margin-left: 64px;
    margin-right: 64px;
  }
}

.contact-options-block {
  grid-auto-flow: dense;
  min-height: 320px;
}
.contact-options-block[data-background] {
  border-radius: var(--shape-border-radius);
}
.contact-options-block--card-background {
  background: var(--cards-background);
  border: 1px solid var(--card-border);
}
.contact-options-block__copy {
  display: flex;
  flex-direction: column;
  grid-column: 1/-1;
  align-self: center;
  z-index: 10;
  margin: 16px;
}
@media (min-width: 768px) {
  .contact-options-block__copy {
    padding: 64px 0;
    margin: 0 24px;
  }
}
@media (min-width: 1600px) {
  .contact-options-block__copy {
    margin: 0 32px;
  }
}
@media (min-width: 1280px) {
  .contact-options-block__copy {
    margin: 0 40px;
  }
}
.contact-options-block__copy--align-center {
  align-items: center;
  text-align: center;
}
.contact-options-block__copy--align-right {
  align-items: flex-end;
}
.contact-options-block__copy .heading__title {
  padding-top: 0;
}
.contact-options-block__copy__actions {
  display: grid;
  grid-template-columns: 1fr;
  grid-column: 1/-1;
  gap: 8px;
  margin: 16px 0 0;
}
@media (min-width: 768px) {
  .contact-options-block__copy__actions {
    grid-template-columns: repeat(2, 1fr);
    width: auto;
    margin: 24px 0 0;
    gap: 16px;
  }
  .contact-options-block__copy__actions .button {
    flex-direction: column;
    padding: 16px 12px;
    gap: 16px;
  }
  .contact-options-block__copy__actions .button .icon {
    width: 48px;
    height: 48px;
  }
}
@media (min-width: 1024px) {
  .contact-options-block__copy__actions {
    grid-template-columns: repeat(4, auto);
  }
  .contact-options-block__copy__actions .button {
    flex-direction: column;
    padding: 16px 24px;
    gap: 24px;
  }
  .contact-options-block__copy__actions .button .icon {
    width: 56px;
    height: 56px;
  }
}
.contact-options-block__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  z-index: 0;
}
.contact-options-block__background:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  background: rgba(255, 255, 255, 0.56);
  border-width: var(--banner-border, 0);
  border-style: solid;
  border-color: var(--banner-border);
  border-radius: var(--shape-border-radius);
}
.contact-options-block__background__heading {
  text-shadow: 0 0 40px rgba(0, 0, 0, 0.4);
}
.contact-options-block__background__picture {
  width: 100%;
  height: 100%;
}
.contact-options-block__background__picture .image {
  height: 100%;
  border-radius: 0;
}
.contact-options-block[data-dark-mode] .contact-options-block__background:after {
  background: rgba(0, 0, 0, 0.56);
}

.featured-list__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 8px;
  box-sizing: border-box;
  justify-content: center;
  grid-column: 1/-1;
  gap: 8px;
  margin-top: 16px;
}
@media (min-width: 768px) {
  .featured-list__grid {
    grid-column-gap: 12px;
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 1280px) {
  .featured-list__grid {
    grid-column-gap: 16px;
    grid-template-columns: repeat(6, 1fr);
  }
}
@media (min-width: 1600px) {
  .featured-list__grid {
    grid-column-gap: 16px;
    grid-template-columns: repeat(6, 1fr);
  }
}
@media (min-width: 768px) {
  .featured-list__grid {
    gap: 16px;
  }
}
.featured-list .scroll-handler-container__slider {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media (min-width: 768px) {
  .featured-list .scroll-handler-container__slider {
    gap: 16px;
  }
}
.featured-list:not([data-card-background]) .scroll-handler-container__slider {
  gap: 32px;
}
@media (min-width: 768px) {
  .featured-list:not([data-card-background]) .scroll-handler-container__slider {
    gap: 64px;
  }
}
@media (min-width: 1280px) {
  .featured-list:not([data-card-background]) .scroll-handler-container__slider {
    gap: 80px;
  }
}
@media (min-width: 768px) {
  .featured-list .scroll-handler-container__slider {
    mask-image: linear-gradient(to right, transparent, black 128px, black calc(100% - 128px), transparent);
  }
  .featured-list .scroll-handler-container__buttons {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.slider-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 128px;
  margin: 0;
  box-sizing: border-box;
  border-radius: var(--shape-border-radius);
}
.slider-item--background {
  background: var(--featured-list-background);
  border: 1px solid var(--featured-list-border);
  padding: 12px;
}
@media (min-width: 768px) {
  .slider-item--background {
    padding: 16px;
  }
}
.slider-item--background.slider-item --slider {
  padding: 24px 16px;
}
.slider-item--slider {
  flex: 1 0 auto;
  width: auto;
  height: 80px;
  min-width: 128px;
  max-width: 160px;
}
@media (min-width: 768px) {
  .slider-item--slider {
    height: 96px;
  }
}
.slider-item[href] {
  cursor: pointer;
}
.slider-item[href]:focus .picture, .slider-item[href]:focus-within .picture {
  transform: scale(0.92);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .slider-item[href]:hover .picture {
    transform: scale(0.92);
  }
}
.slider-item--background[href]:focus, .slider-item--background[href]:focus-within {
  border-color: var(--brand-main-active);
  background: rgba(var(--brand-main-active-rgb), 0.08);
}
@media only screen and (hover: hover) and (pointer: fine) {
  .slider-item--background[href]:hover {
    border-color: var(--brand-main-active);
    background: rgba(var(--brand-main-active-rgb), 0.08);
  }
}
.slider-item[data-monochrome] {
  filter: grayscale(1);
  opacity: 0.64;
  transition: filter 0.24s, opacity 0.24s, transform 0.24s;
}
.slider-item[data-monochrome]:focus, .slider-item[data-monochrome]:focus-within {
  filter: grayscale(0);
  opacity: 1;
}
@media only screen and (hover: hover) and (pointer: fine) {
  .slider-item[data-monochrome]:hover {
    filter: grayscale(0);
    opacity: 1;
  }
}
.slider-item .picture {
  height: 100%;
  min-width: 128px;
  max-width: 240px;
  pointer-evets: none;
  transition: inherit;
  border-radius: unset;
}
.slider-item .picture .image {
  object-fit: contain;
}

.highlights {
  grid-row-gap: 24px;
}
.highlights__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 24px;
  grid-column: 1/-1;
}
.highlights__container .highlight {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  flex: 1 0 auto;
  text-align: center;
  align-self: start;
}
.highlights__container .highlight > .icon {
  color: var(--brand-main);
  font-size: 48px;
  height: 48px;
}
.highlights__container .highlight__label {
  padding: 0.64rem 0 0;
  font-size: 1rem;
}
.highlights__container .highlight__description {
  margin-top: 4px;
  font-size: 0.8rem;
}
.highlights__container .highlight .link * {
  font-size: 0.8rem;
}

.link-list__copy {
  grid-column: 1/-1;
  margin: 0 0 32px;
}
.link-list__list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  grid-column: 1/-1;
}
@media only screen and (max-width: 767px) and (hover: none) and (pointer: coarse) {
  .link-list__list {
    /* IE, Legacy Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;
    /* Chrome, Safari and Opera */
    overflow-x: scroll;
    flex-wrap: nowrap;
    margin: 0 -16px;
  }
  .link-list__list::-webkit-scrollbar {
    display: none;
  }
  .link-list__list:before, .link-list__list:after {
    content: "_";
    flex: 0 0 8px;
    height: 0;
    color: transparent;
    pointer-events: none;
  }
}

.media-block .picture, .media-block .video {
  grid-column: 1/-1;
}
@media (min-width: 768px) {
  .media-block .picture, .media-block .video {
    grid-column: 2/-2;
  }
}
@media (min-width: 1280px) {
  .media-block .picture, .media-block .video {
    grid-column: 3/-3;
  }
}
@media (min-width: 1600px) {
  .media-block .picture, .media-block .video {
    grid-column: 5/-5;
  }
}

.heading + .photo-gallery__grid {
  margin-top: 24px;
}
.photo-gallery .picture {
  height: 100%;
}
.photo-gallery__actions {
  grid-column: 1/-1;
  margin: 16px 0;
}
.photo-gallery__grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-column-gap: 8px;
  box-sizing: border-box;
  justify-content: center;
  grid-column: 1/-1;
  grid-gap: 8px;
  grid-template-areas: "p1 p1 p1 p1 p2 p2 p2 p2" "p3 p3 p3 p3 p4 p4 p4 p4";
  /* Gallery layouts */
}
@media (min-width: 768px) {
  .photo-gallery__grid {
    grid-column-gap: 12px;
    grid-template-columns: repeat(16, 1fr);
  }
}
@media (min-width: 1280px) {
  .photo-gallery__grid {
    grid-column-gap: 16px;
    grid-template-columns: repeat(16, 1fr);
  }
}
@media (min-width: 1600px) {
  .photo-gallery__grid {
    grid-column-gap: 16px;
    grid-template-columns: repeat(16, 1fr);
  }
}
@media (min-width: 1920px) {
  .photo-gallery__grid {
    grid-template-columns: repeat(16, 1fr);
  }
}
@media (min-width: 768px) {
  .photo-gallery__grid {
    grid-gap: 16px;
  }
}
.photo-gallery__grid__container {
  margin-top: 0;
}
.photo-gallery__grid__container:nth-of-type(1) {
  grid-area: p1;
}
.photo-gallery__grid__container:nth-of-type(2) {
  grid-area: p2;
}
.photo-gallery__grid__container:nth-of-type(3) {
  grid-area: p3;
}
.photo-gallery__grid__container:nth-of-type(4) {
  grid-area: p4;
}
.photo-gallery__grid__container:nth-of-type(5) {
  grid-area: p5;
}
.photo-gallery__grid__container:nth-of-type(5) {
  display: none;
}
@media (min-width: 768px) {
  .photo-gallery__grid--gallery {
    grid-template-areas: "p1 p1 p1 p1 p2 p2 p2 p2 p3 p3 p3 p3 p4 p4 p4 p4";
  }
}
@media (min-width: 768px) {
  .photo-gallery__grid--grid {
    grid-template-areas: "p1 p1 p1 p1 p1 p1 p1 p1 p2 p2 p2 p2 p2 p2 p2 p2" "p3 p3 p3 p3 p3 p3 p3 p3 p4 p4 p4 p4 p4 p4 p4 p4";
  }
}
.photo-gallery__grid--masonry-a {
  grid-template-areas: "p1 p1 p1 p2 p2 p2 p2 p2" "p3 p3 p3 p3 p3 p3 p3 p3" "p4 p4 p4 p4 p4 p5 p5 p5";
}
@media (min-width: 768px) {
  .photo-gallery__grid--masonry-a {
    grid-template-areas: "p1 p1 p1 p1 p1 p1 p1 p1 p2 p2 p2 p2 p2 p2 p2 p2" "p3 p3 p3 p3 p3 p4 p4 p4 p4 p4 p4 p5 p5 p5 p5 p5";
  }
}
.photo-gallery__grid--masonry-a .photo-gallery__grid__container:nth-of-type(5) {
  display: initial;
}
.photo-gallery__grid--masonry-b {
  grid-template-areas: "p1 p1 p1 p2 p2 p2 p2 p2" "p3 p3 p3 p3 p3 p4 p4 p4";
}
@media (min-width: 768px) {
  .photo-gallery__grid--masonry-b {
    grid-template-areas: "p1 p1 p1 p1 p2 p2 p2 p2 p2 p2 p2 p2 p2 p2 p2 p2" "p3 p3 p3 p3 p3 p3 p3 p3 p3 p4 p4 p4 p4 p4 p4 p4";
  }
}
.photo-gallery__grid--masonry-c {
  grid-template-areas: "p1 p1 p1 p1 p3 p3 p3 p3" "p2 p2 p2 p2 p3 p3 p3 p3" "p4 p4 p4 p4 p4 p4 p4 p4";
}
@media (min-width: 768px) {
  .photo-gallery__grid--masonry-c {
    grid-template-areas: "p1 p1 p1 p1 p1 p2 p2 p2 p2 p2 p3 p3 p3 p3 p3 p3" "p4 p4 p4 p4 p4 p4 p4 p4 p4 p4 p3 p3 p3 p3 p3 p3";
  }
}
.photo-gallery__grid--masonry-d {
  grid-template-areas: "p1 p1 p1 p1 p1 p1 p1 p1" "p1 p1 p1 p1 p1 p1 p1 p1" "p2 p2 p2 p2 p3 p3 p3 p3" "p4 p4 p4 p4 p4 p4 p4 p4";
}
@media (min-width: 768px) {
  .photo-gallery__grid--masonry-d {
    grid-template-areas: "p1 p1 p1 p1 p1 p1 p1 p1 p2 p2 p2 p2 p2 p3 p3 p3 p3" "p1 p1 p1 p1 p1 p1 p1 p1 p4 p4 p4 p4 p4 p4 p4 p4 p4";
  }
}

.photo-block-lightbox {
  border-radius: var(--shape-border-radius);
}
@media (max-width: 767px) {
  .photo-block-lightbox {
    margin: auto 16px !important;
  }
}
.photo-block-lightbox .section__content {
  height: 100%;
  overflow: hidden;
}
.photo-block-lightbox .section__content .picture {
  height: 100%;
  width: 100%;
  border-radius: 0;
}
.photo-block-lightbox .section__content .picture .image {
  object-fit: contain;
}

#overlay-modal-photo-block-lightbox[data-overlay-state=visible] {
  background: rgba(0, 0, 0, 0.8);
}

.switch-list__blocks {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 8px;
  box-sizing: border-box;
  justify-content: center;
  grid-column: 1/-1;
  grid-template-rows: 48px auto;
  margin-top: 16px;
  grid-row-gap: 16px;
}
@media (min-width: 768px) {
  .switch-list__blocks {
    grid-column-gap: 12px;
    grid-template-columns: repeat(12, 1fr);
  }
}
@media (min-width: 1280px) {
  .switch-list__blocks {
    grid-column-gap: 16px;
    grid-template-columns: repeat(18, 1fr);
  }
}
@media (min-width: 1600px) {
  .switch-list__blocks {
    grid-column-gap: 16px;
    grid-template-columns: repeat(18, 1fr);
  }
}
@media (min-width: 768px) {
  .switch-list__blocks {
    margin-top: 32px;
    grid-row-gap: 8px;
  }
}

.switch-list-block {
  display: grid;
  grid-column: 1/-1;
  grid-row: 1/-1;
  grid-template-columns: subgrid;
  grid-template-rows: subgrid;
  grid-column-gap: 12px;
}
.switch-list-block__preview {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  grid-row: 1;
}
@media (min-width: 768px) {
  .switch-list-block__preview {
    grid-column: 1/3;
  }
}
@media (min-width: 1024px) {
  .switch-list-block__preview {
    grid-column: 1/5;
    justify-content: start;
    align-self: center;
    gap: 12px;
    padding: 12px 8px 12px 12px;
  }
  .switch-list-block__preview:after, .switch-list-block__preview:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    border-radius: var(--shape-border-radius);
    transition: opacity 0.48s cubic-bezier(0.33, 1, 0.68, 1);
  }
  .switch-list-block__preview:before {
    background: linear-gradient(to right, var(--cards-background) 50%, transparent 75%);
    z-index: -2;
    background-size: 300%;
  }
  .switch-list-block__preview:after {
    border: var(--card-border-property);
    z-index: -1;
  }
  .switch-list-block__preview:focus:after, .switch-list-block__preview:focus:before, .switch-list-block__preview:focus-within:after, .switch-list-block__preview:focus-within:before {
    opacity: 0.5 !important;
  }
}
@media only screen and (min-width: 1024px) and (hover: hover) and (pointer: fine) {
  .switch-list-block__preview:hover:after, .switch-list-block__preview:hover:before {
    opacity: 0.5 !important;
  }
}
@media (min-width: 1024px) {
  .switch-list-block__preview:active:after, .switch-list-block__preview:active:before {
    opacity: 0.8 !important;
  }
}
@media (min-width: 1280px) {
  .switch-list-block__preview {
    padding: 16px 12px 16px 16px;
  }
}
@media (min-width: 1600px) {
  .switch-list-block__preview {
    grid-column: 1/6;
  }
}
.switch-list-block__preview__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  padding: 12px;
  box-sizing: border-box !important;
  font-size: 28px;
}
@media (max-width: 767px) {
  .switch-list-block__preview__icon {
    transform: scale(1.06);
    transition: transform 0.32s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .switch-list-block__preview__icon {
    width: 56px;
    height: 56px;
    font-size: 32px;
  }
}
@media (min-width: 1280px) {
  .switch-list-block__preview__icon {
    width: 56px;
    height: 56px;
    font-size: 32px;
  }
}
.switch-list-block__preview__label {
  font-size: 16px;
  letter-spacing: 0.64px;
  line-height: 24px;
  font-weight: bold;
}
@media (max-width: 1023px) {
  .switch-list-block__preview__label {
    display: none;
  }
}
@media (min-width: 1280px) {
  .switch-list-block__preview__label {
    font-size: 20px;
    letter-spacing: 0.72px;
    line-height: 28px;
  }
}
.switch-list-block__radio {
  display: contents;
  width: 0;
  height: 0;
  font-size: 0;
  visibility: hidden;
}
.switch-list-block__content {
  background: var(--cards-background);
  border: var(--card-border-property);
  border-radius: var(--shape-border-radius);
  display: flex;
  justify-content: stretch;
  align-items: space-between;
  flex-direction: column-reverse;
  position: relative;
  grid-column: 1/-1;
  grid-row: 2;
  margin-top: 16px;
  transition: opacity 1.6s cubic-bezier(0, 0.55, 0.45, 1), filter 0.88s cubic-bezier(0, 0.55, 0.45, 1);
}
@media (min-width: 768px) {
  .switch-list-block__content {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 8px;
    box-sizing: border-box;
    justify-content: center;
    grid-row: 1/-1;
    align-self: center;
    height: auto;
    margin-top: 64px;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .switch-list-block__content {
    grid-column-gap: 12px;
    grid-template-columns: repeat(12, 1fr);
  }
}
@media (min-width: 768px) and (min-width: 1280px) {
  .switch-list-block__content {
    grid-column-gap: 16px;
    grid-template-columns: repeat(8, 1fr);
  }
}
@media (min-width: 768px) and (min-width: 1600px) {
  .switch-list-block__content {
    grid-column-gap: 16px;
    grid-template-columns: repeat(12, 1fr);
  }
}
@media (min-width: 1024px) {
  .switch-list-block__content {
    grid-column: 5/-1;
    margin: auto 0;
  }
}
@media (min-width: 1600px) {
  .switch-list-block__content {
    grid-column: 7/-1;
  }
}
.switch-list-block__content .heading {
  grid-column: 1/-1;
  padding: 16px 12px;
  margin: 0;
  z-index: 20;
}
@media (max-width: 767px) {
  .switch-list-block__content .heading {
    grid-row: 2;
  }
}
@media (min-width: 768px) {
  .switch-list-block__content .heading {
    grid-column: 1/8;
    padding: 24px 16px;
  }
}
@media (min-width: 1280px) {
  .switch-list-block__content .heading {
    padding: 32px 24px;
    grid-column: 1/5;
  }
}
@media (min-width: 1600px) {
  .switch-list-block__content .heading {
    grid-column: 1/8;
  }
}
.switch-list-block__content .heading__title {
  padding-top: 0;
}
.switch-list-block__content .picture {
  grid-column: 1/-1;
  grid-row: 1;
  flex: 1 1 280px;
  border-bottom-left-radius: unset;
  z-index: 10;
}
@media (max-width: 767px) {
  .switch-list-block__content .picture {
    border-bottom-right-radius: unset;
  }
}
@media (min-width: 768px) {
  .switch-list-block__content .picture {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    grid-column: -6/-1;
    border-top-left-radius: unset;
    width: 100%;
    height: 100%;
  }
}
@media (min-width: 1280px) {
  .switch-list-block__content .picture {
    grid-column: -5/-1;
  }
}
@media (min-width: 1600px) {
  .switch-list-block__content .picture {
    grid-column: -6/-1;
  }
}
.switch-list-block__content .picture [data-image-fit=contain] {
  padding: 16px;
  box-sizing: border-box;
}
input:not(:checked) ~ .switch-list-block__content {
  opacity: 0;
}
@media (max-width: 1023px) {
  input:not(:checked) ~ .switch-list-block__preview .switch-list-block__preview__icon {
    opacity: 32%;
    transform: scale(0.88);
  }
}
input:not(:checked) ~ .switch-list-block__preview:before, input:not(:checked) ~ .switch-list-block__preview:after {
  opacity: 0;
}
input:checked ~ .switch-list-block__preview:before {
  animation: 4s preview-background-progress;
}

@media (max-width: 1023px) {
  [data-switch-index="1"] {
    grid-column: 1;
  }
  [data-switch-index="2"] {
    grid-column: 2;
  }
  [data-switch-index="3"] {
    grid-column: 3;
  }
  [data-switch-index="4"] {
    grid-column: 4;
  }
  [data-switch-index="5"] {
    grid-column: 5;
  }
  [data-switch-index="6"] {
    grid-column: 6;
  }
  [data-switch-index="7"] {
    grid-column: 7;
  }
  [data-switch-index="8"] {
    grid-column: 8;
  }
  [data-switch-index="9"] {
    grid-column: 9;
  }
  [data-switch-index="10"] {
    grid-column: 10;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  [data-switch-rows="1"] {
    grid-template-columns: repeat(1, 1fr);
  }
  [data-switch-rows="2"] {
    grid-template-columns: repeat(2, 1fr);
  }
  [data-switch-rows="3"] {
    grid-template-columns: repeat(3, 1fr);
  }
  [data-switch-rows="4"] {
    grid-template-columns: repeat(4, 1fr);
  }
  [data-switch-rows="5"] {
    grid-template-columns: repeat(5, 1fr);
  }
  [data-switch-rows="6"] {
    grid-template-columns: repeat(6, 1fr);
  }
  [data-switch-rows="7"] {
    grid-template-columns: repeat(7, 1fr);
  }
  [data-switch-rows="8"] {
    grid-template-columns: repeat(8, 1fr);
  }
  [data-switch-rows="9"] {
    grid-template-columns: repeat(9, 1fr);
  }
  [data-switch-rows="10"] {
    grid-template-columns: repeat(10, 1fr);
  }
}
@media (min-width: 1024px) {
  [data-switch-index="1"] {
    grid-row: 1;
  }
  [data-switch-rows="1"] {
    grid-template-rows: repeat(1, 1fr);
  }
  [data-switch-index="2"] {
    grid-row: 2;
  }
  [data-switch-rows="2"] {
    grid-template-rows: repeat(2, 1fr);
  }
  [data-switch-index="3"] {
    grid-row: 3;
  }
  [data-switch-rows="3"] {
    grid-template-rows: repeat(3, 1fr);
  }
  [data-switch-index="4"] {
    grid-row: 4;
  }
  [data-switch-rows="4"] {
    grid-template-rows: repeat(4, 1fr);
  }
  [data-switch-index="5"] {
    grid-row: 5;
  }
  [data-switch-rows="5"] {
    grid-template-rows: repeat(5, 1fr);
  }
  [data-switch-index="6"] {
    grid-row: 6;
  }
  [data-switch-rows="6"] {
    grid-template-rows: repeat(6, 1fr);
  }
  [data-switch-index="7"] {
    grid-row: 7;
  }
  [data-switch-rows="7"] {
    grid-template-rows: repeat(7, 1fr);
  }
  [data-switch-index="8"] {
    grid-row: 8;
  }
  [data-switch-rows="8"] {
    grid-template-rows: repeat(8, 1fr);
  }
  [data-switch-index="9"] {
    grid-row: 9;
  }
  [data-switch-rows="9"] {
    grid-template-rows: repeat(9, 1fr);
  }
  [data-switch-index="10"] {
    grid-row: 10;
  }
  [data-switch-rows="10"] {
    grid-template-rows: repeat(10, 1fr);
  }
}
@keyframes preview-background-progress {
  0% {
    background-position: 100%;
  }
  100% {
    background-position: 30%;
  }
}
.text-block {
  grid-auto-flow: dense;
  /* Styling for even cards */
}
.text-block[data-media] {
  min-height: 320px;
}
@media (min-width: 1024px) {
  .text-block[data-media] {
    min-height: 360px;
  }
}
@media (min-width: 1600px) {
  .text-block[data-media] {
    min-height: 400px;
  }
}
.text-block[data-background]:not([data-media]) .text-block__copy {
  background: var(--text-block-background);
  border-width: var(--text-block-border, 0);
  border-style: solid;
  border-color: var(--text-block-border);
  border-radius: var(--shape-border-radius);
  overflow: hidden;
}
.text-block[data-background] .text-block__copy {
  padding: 0 24px 16px;
}
@media (min-width: 768px) {
  .text-block[data-background] .text-block__copy {
    padding: 24px 32px;
  }
}
@media (min-width: 1280px) {
  .text-block[data-background] .text-block__copy {
    padding: 32px 40px;
  }
}
@media (min-width: 1600px) {
  .text-block[data-background] .text-block__copy {
    padding: 40px 48px;
  }
}
.text-block[data-background][data-media] {
  background: var(--text-block-background);
  border-width: var(--text-block-border, 0);
  border-style: solid;
  border-color: var(--text-block-border);
  border-radius: var(--shape-border-radius);
  overflow: hidden;
}
@media (min-width: 1600px) {
  .text-block[data-background][data-media] {
    grid-template-columns: repeat(18, 1fr);
  }
  .text-block[data-background][data-media] .text-block__copy {
    grid-column: span 8/-2;
  }
}
.text-block[data-background] .picture, .text-block[data-background] .video {
  border-radius: 0;
}
.text-block__copy {
  grid-column: 1/-1;
}
@media (min-width: 768px) {
  .text-block__copy {
    grid-column: 2/-2;
  }
}
@media (min-width: 1280px) {
  .text-block__copy {
    grid-column: 3/-3;
  }
}
@media (min-width: 1600px) {
  .text-block__copy {
    grid-column: 5/-5;
  }
}
.text-block__copy .heading {
  margin-top: unset;
  max-width: unset;
}
@media (min-width: 768px) {
  .text-block__copy .heading__title {
    padding-top: unset;
  }
}
.text-block__copy__actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  margin: 1.5rem 0 0;
}
.text-block__copy__actions .button {
  flex: 1 0 auto;
}
.text-block__copy__actions .button:first-child:last-child {
  flex: 0 0 auto;
}
.picture + .text-block__copy, .video + .text-block__copy {
  grid-column: 1/-1;
  align-self: center;
}
@media (min-width: 768px) {
  .picture + .text-block__copy, .video + .text-block__copy {
    grid-column: span 6/-1;
    grid-row: 1;
    padding: 16px 0;
  }
}
@media (min-width: 1024px) {
  .picture + .text-block__copy, .video + .text-block__copy {
    padding: 24px 0;
  }
}
@media (min-width: 1280px) {
  .picture + .text-block__copy, .video + .text-block__copy {
    grid-column: span 8/-2;
    padding: 32px 0;
  }
}
@media (min-width: 1600px) {
  .picture + .text-block__copy, .video + .text-block__copy {
    padding: 40px 0;
  }
}
.picture + .text-block__copy__link, .video + .text-block__copy__link {
  grid-column: 1/-1;
  margin: 16px 0 0;
}
@media (min-width: 768px) {
  .picture + .text-block__copy__link, .video + .text-block__copy__link {
    margin: 24px 0 0;
  }
}
.picture + .text-block__copy__link__icon, .video + .text-block__copy__link__icon {
  margin-left: 6px;
}
.text-block .picture, .text-block .video {
  position: relative;
  grid-column: 1/-1;
  min-height: 240px;
  max-height: 320px;
  border-radius: var(--shape-border-radius);
  overflow: hidden;
}
@media (min-width: 768px) {
  .text-block .picture, .text-block .video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column: 1/span 5;
    max-height: unset;
  }
}
@media (min-width: 1280px) {
  .text-block .picture, .text-block .video {
    grid-column: 1/span 8;
  }
}
.text-block .picture .image, .text-block .video .image {
  height: 100%;
}
@media (min-width: 768px) {
  .text-block:nth-of-type(even):has(* + .text-block__copy) .picture, .text-block:nth-of-type(even):has(* + .text-block__copy) .video {
    grid-column: span 5/-1;
  }
}
@media (min-width: 1280px) {
  .text-block:nth-of-type(even):has(* + .text-block__copy) .picture, .text-block:nth-of-type(even):has(* + .text-block__copy) .video {
    grid-column: span 8/-1;
  }
}
@media (min-width: 768px) {
  .text-block:nth-of-type(even):has(* + .text-block__copy) .text-block__copy {
    grid-column: 1/span 6;
  }
}
@media (min-width: 1280px) {
  .text-block:nth-of-type(even):has(* + .text-block__copy) .text-block__copy {
    grid-column: 2/span 8;
  }
}
@media (min-width: 768px) {
  .text-block:nth-of-type(even) .text-block .video, .text-block:nth-of-type(even) .text-block .picture {
    grid-column: span 5/-1;
  }
}
@media (min-width: 1600px) {
  .text-block:nth-of-type(even) .text-block .video, .text-block:nth-of-type(even) .text-block .picture {
    grid-column: span 8/-1;
  }
}
@media (min-width: 768px) {
  .text-block:nth-of-type(even)[data-background][data-media] .text-block__copy {
    padding-right: 0;
  }
  .text-block:nth-of-type(odd)[data-background][data-media] .text-block__copy {
    padding-left: 0;
  }
}

.unique-selling-points {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* IE, Legacy Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* Chrome, Safari and Opera */
  padding-left: 16px;
  padding-right: 16px;
  gap: 16px;
  overflow-x: scroll;
  margin: 0;
  border-top: 1px solid var(--card-border);
  border-bottom: 1px solid var(--card-border);
  background: var(--cards-background);
}
.unique-selling-points::-webkit-scrollbar {
  display: none;
}
@media (min-width: 768px) {
  .unique-selling-points {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media (min-width: 1024px) {
  .unique-selling-points {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (min-width: 1280px) {
  .unique-selling-points {
    padding-left: 64px;
    padding-right: 64px;
  }
}
@media (min-width: 1280px) {
  .unique-selling-points {
    gap: 24px;
  }
}
.unique-selling-points[data-usps="1"] .unique-selling-points__group {
  animation-duration: 4s;
}
.unique-selling-points[data-usps="2"] .unique-selling-points__group {
  animation-duration: 8s;
}
.unique-selling-points[data-usps="3"] .unique-selling-points__group {
  animation-duration: 12s;
}
.unique-selling-points[data-usps="4"] .unique-selling-points__group {
  animation-duration: 16s;
}
.unique-selling-points[data-usps="5"] .unique-selling-points__group {
  animation-duration: 20s;
}
.unique-selling-points[data-usps="6"] .unique-selling-points__group {
  animation-duration: 24s;
}
.unique-selling-points[data-usps="7"] .unique-selling-points__group {
  animation-duration: 28s;
}
.unique-selling-points[data-usps="8"] .unique-selling-points__group {
  animation-duration: 32s;
}
.unique-selling-points__group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 0 0 auto;
  gap: inherit;
  padding: 12px 0;
  will-change: transform;
  animation: scrolling linear infinite paused;
  transform: translateX(calc(-100% - 16px));
}
@media (min-width: 768px) {
  .unique-selling-points__group {
    transform: translateX(calc(-100% - 24px));
  }
}
.unique-selling-points__group--clone {
  display: none;
}
.unique-selling-points__group .usp-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  gap: 4px;
}
@media (min-width: 768px) {
  .unique-selling-points__group .usp-item {
    gap: 8px;
  }
}
.unique-selling-points__group .usp-item .icon {
  color: var(--brand-main);
  flex: 0 0 auto;
}

@keyframes scrolling {
  0% {
    transform: translateX(0);
  }
}